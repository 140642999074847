import { createReducer, on } from '@ngrx/store';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { DayTime } from '@t12/common/world/enums/day-time.enum';
import { WeatherKind } from '@t12/common/world/enums/weather-kind.enum';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { clearSkyTime } from '@t12/world/constants/clear-sky-time.constant';
import { nightSkyWeather } from '@t12/world/constants/night-sky-weather.constant';
import { WorldActions } from '../actions/world-actions';
import { initialWorldState } from '../index';

export const WorldReducer = createReducer(
  initialWorldState,

  on(WorldActions.init, () => ({
    ...initialWorldState,
  })),

  on(WorldActions.setWeather, (worldState, { weather }) => ({
    ...worldState,
    weather,
    time: nightSkyWeather.includes(weather) ? DayTime.NIGHT : worldState.time,
  })),

  on(WorldActions.setTime, (worldState, { time }) => ({
    ...worldState,
    time,
    weather: clearSkyTime.includes(time)
      ? WeatherKind.CLEAR
      : worldState.weather,
  })),

  on(WorldActions.loadWorld, WorldActions.teleportTo, (worldState) => ({
    ...worldState,
    loadingStatus: LoadingStatus.IN_PROGRESS,
  })),

  on(WorldActions.loadWorldSuccess, (worldState, { world }) => ({
    ...worldState,
    code: world.code,
    enableWeather: world.enableWeather,
    music: world.music,
    name: world.name,
    tiles: world.tiles,
    loadingStatus: LoadingStatus.LOADED,
  })),

  on(WorldActions.addItemTile, (worldState, { x, y, item }) => ({
    ...worldState,
    tiles: worldState.tiles.map((row, rowIndex) =>
      rowIndex === y
        ? row.map((tile, colIndex) =>
            colIndex === x
              ? {
                  ...tile,
                  item:
                    tile.item?.code === item.code
                      ? {
                          ...tile.item,
                          amount: tile.item.amount + item.amount,
                        }
                      : tile.item
                        ? tile.item
                        : item,
                }
              : tile,
          )
        : row,
    ),
  })),

  on(WorldActions.addContainerTile, (worldState, { x, y, container }) => ({
    ...worldState,
    tiles: worldState.tiles.map((row, rowIndex) =>
      rowIndex === y
        ? row.map((tile, colIndex) =>
            colIndex === x
              ? {
                  ...tile,
                  container: {
                    img: container.img,
                    kind: container.kind,
                  } as IContainer,
                }
              : tile,
          )
        : row,
    ),
  })),

  on(WorldActions.removeEntity, (worldState, { x, y, entity }) => ({
    ...worldState,
    tiles: worldState.tiles.map((row, rowIndex) =>
      rowIndex === y
        ? row.map((tile, colIndex) =>
            colIndex === x
              ? {
                  ...tile,
                  [entity]: null,
                }
              : tile,
          )
        : row,
    ),
  })),

  on(WorldActions.removeItemTile, (worldState, { x, y, amount }) => ({
    ...worldState,
    tiles: worldState.tiles.map((row, rowIndex) =>
      rowIndex === y
        ? row.map((tile, colIndex) =>
            colIndex === x
              ? {
                  ...tile,
                  item:
                    tile.item?.amount - amount > 0
                      ? { ...tile.item, amount: tile.item?.amount - amount }
                      : undefined,
                }
              : tile,
          )
        : row,
    ),
  })),
);
