import { createReducer, on } from '@ngrx/store';
import { shopState } from '@t12/shop/store';
import { ShopActions } from '../actions/shop.actions';

export const ShopReducer = createReducer(
  shopState,

  on(ShopActions.init, () => shopState),

  on(ShopActions.openShopSuccess, (_, { shop }) => shop),
);
