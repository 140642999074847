import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { filter, map, tap, switchMap, withLatestFrom, take } from 'rxjs';

@Injectable()
export class InventoryPickEffects {
  private _pickItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItem),
      filter(({ item }) => item.amount > 0),
      switchMap(({ item }) =>
        this._store.select(getFreeInventorySlotAmount(item)).pipe(
          take(1),
          map((freeSlotsAmount) => {
            const pickedAmount = Math.min(freeSlotsAmount, item.amount);

            if (freeSlotsAmount > 0 && freeSlotsAmount >= pickedAmount) {
              return InventoryActions.pickItemSuccess({
                item,
                amount: pickedAmount,
              });
            } else {
              return InventoryActions.pickItemFailed();
            }
          }),
        ),
      ),
    ),
  );

  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItemSuccess),
      withLatestFrom(this._store.select(getPlayerName)),
      tap(([{ item, amount }, playerName]) => {
        this._audioService.playSound('miscs', 'drop_leather', 'ogg');
        this._inventoryDbService.pickItem(item.code, amount);
        this._questGoalsService.updateCollectGoals();
        this._notificationService.addNotification(
          'validation',
          `+${amount} ${item.name}`,
          5000,
          item.img,
        );
        this._chatService.addChatLog(
          'action',
          playerName,
          `a ramassé l'objet "${item.name}"`,
          ChatLogKind.Bonus,
        );
      }),
      map(([{ item, amount }]) =>
        InventoryActions.addItemInInventory({ item, amount }),
      ),
    ),
  );

  private _pickItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.pickItemFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
  ) {}
}
