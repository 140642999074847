import { createReducer, on } from '@ngrx/store';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { initialJobState, JobState } from '../index';

export const JobReducer = createReducer(
  initialJobState,

  on(JobActions.init, () => ({
    ...initialJobState,
  })),

  on(JobActions.setActiveJobIndex, (jobState: JobState, { index }) => ({
    ...jobState,
    activeJobIndex: index,
  })),

  on(JobActions.setActiveJobKind, (jobState: JobState, { kind }) => ({
    ...jobState,
    activeJobKind: kind,
  })),

  on(JobActions.setActiveTab, (jobState: JobState, { tab }) => ({
    ...jobState,
    activeTab: tab,
  })),

  on(JobActions.setRecipes, (jobState: JobState, { recipes }) => ({
    ...jobState,
    recipes,
  })),

  on(JobActions.setDisplayedRecipes, (jobState: JobState, { recipes }) => ({
    ...jobState,
    displayedRecipes: recipes,
  })),

  on(JobActions.setCurrentPage, (jobState: JobState, { page }) => ({
    ...jobState,
    currentPage: page,
  })),

  on(JobActions.setMaxPage, (jobState: JobState, { maxPage }) => ({
    ...jobState,
    maxPage,
  })),

  on(JobActions.setWorkshop, (jobState: JobState, { workshop }) => ({
    ...jobState,
    workshop,
  })),
);
