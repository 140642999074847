import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { switchMap, of, tap, take, map, delay } from 'rxjs';
import { CharactersActions } from '../../actions/characters.actions';

dayjs.locale('fr');

@Injectable()
export class CharactersEffects {
  private _setCharacters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      switchMap(({ characters }) => {
        for (const character of characters) {
          // if (character.state !== 'fight' && !character.static)
          //   this._characterService.createPatrolInterval(character.idCharacter);

          const activeGoalQuest =
            this._questGoalsService.findGoalFromPlayerQuest(
              character.code,
              'exploration',
            );
          if (!activeGoalQuest) continue;

          if (activeGoalQuest.amount !== activeGoalQuest.amountTotal) {
            this._store.dispatch(
              QuestActions.updateGoal({ goal: activeGoalQuest, amount: 1 }),
            );
            this._questGoalsService.addNotificationQuestGoal(activeGoalQuest);
          }
        }

        return of(CharactersActions.setCharactersSuccess());
      }),
    ),
  );

  private _npcDeath$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.npcDeath),
      tap(({ target }) => {
        this._activeTargetService.setActiveTarget(null);
        this._timersService.stopTimerFightByID(target.idCharacter);
      }),
      switchMap(({ attacker, target }) => {
        const { code, type } = target as NPC | Monster;

        return this._playerDbService
          .playerKillCharacter({
            playerId: (attacker as Player).id,
            code,
            kind: type,
          })
          .pipe(
            take(1),
            tap(({ xp, container }) => {
              this._store.dispatch(
                CharactersActions.rewardFight({ target, xp, container }),
              );
            }),
            map(() => ({ attacker, target })),
            delay(1000),
            map(({ attacker, target }) =>
              CharactersActions.npcDeathSuccess({ attacker, target }),
            ),
          );
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
    private readonly _timersService: TimersManagerService,
  ) {}
}
