import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankTab } from '@t12/bank/components/bank/bank.component';
import { BankDbService } from '@t12/bank/services/bank-db/bank-db.service';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getBankGold,
  getBankTab,
  getDepositItemAmount,
  getItemsBank,
} from '@t12/bank/store/selectors/bank.selectors';
import { getPlayerGold } from '@t12/characters/store/selectors/characters.selectors';
import { countItem } from '@t12/common/inventory/functions/count-item/count-item.function';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import {
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';

@Injectable()
export class BankEffects {
  private _openShop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.openBank),
      switchMap(() =>
        this._bankDbService.getBankPlayer().pipe(
          take(1),
          switchMap((bank) => [
            BankActions.openBankSuccess({ bank }),
            HudDisplayActions.hideHud({ name: 'dialog' }),
            HudDisplayActions.showHud({ name: 'bank' }),
          ]),
          catchError(() => of(ShopActions.openShopFailed())),
        ),
      ),
    ),
  );

  private _openShopFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.openShopFailed),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          'Impossible de récupérer les informations de la banque',
        ),
      ),
    ),
  );

  private _deposit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.deposit),
      withLatestFrom(this._store.select(getBankTab)),
      map(([_, tab]) => {
        if (tab === BankTab.Inventory) return BankActions.depositTo();
        else if (tab === BankTab.Bank) return BankActions.depositFrom();
      }),
    ),
  );

  private _setDepositItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.setDepositItem),
      withLatestFrom(
        this._store.select(getBankTab),
        this._store.select(getPlayerInventory),
        this._store.select(getItemsBank),
      ),
      map(([{ item }, tab, inventory, bank]) => {
        if (tab === BankTab.Inventory) {
          return BankActions.setDepositItemAmountMax({
            amountMax: countItem(item.code, inventory),
          });
        } else {
          return BankActions.setDepositItemAmountMax({
            amountMax: countItem(item.code, bank),
          });
        }
      }),
    ),
  );

  private _setDepositGold$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.setDepositGold),
      withLatestFrom(
        this._store.select(getBankTab),
        this._store.select(getPlayerGold),
        this._store.select(getBankGold),
      ),
      map(([{ gold }, tab, playerGold, bankGold]) => {
        if (tab === BankTab.Inventory) {
          return BankActions.setDepositGoldSuccess({
            gold: Math.min(gold, playerGold),
          });
        } else {
          return BankActions.setDepositGoldSuccess({
            gold: Math.min(gold, bankGold),
          });
        }
      }),
    ),
  );
  private _resetDepositItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        BankActions.incDepositItemAmount,
        BankActions.depositToItemSuccess,
        BankActions.depositFromItemSuccess,
      ),
      withLatestFrom(this._store.select(getDepositItemAmount)),
      filter(([_, amount]) => amount <= 0),
      map(() => BankActions.resetDepositItem()),
    ),
  );

  private _updateItemsDisplayed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        BankActions.openBankSuccess,
        BankActions.depositToItemSuccess,
        BankActions.depositFromItemSuccess,
      ),
      map(() => BankActions.updateItemsDisplayed()),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _bankDbService: BankDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
