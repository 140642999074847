import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayer,
  getCharacterById,
} from '@t12/characters/store/selectors/characters.selectors';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getAllHudDisplay } from '@t12/overlay/store/selectors/hud-display.selectors';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { ShopManagerService } from '@t12/shop/services/shop/shop-manager.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';
import { map, switchMap, take, tap, withLatestFrom, delay, filter } from 'rxjs';

@Injectable()
export class MoveCharacterEffects {
  private _move$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.move),
      switchMap((action) =>
        this._store.select(getCharacterById(action.idCharacter)).pipe(
          take(1),
          map((character) => ({ action, character })),
        ),
      ),
      filter(({ character, action: { direction } }) =>
        this._characterService.canMove(character, direction),
      ),
      switchMap(({ action: { idCharacter, direction }, character }) => {
        if (isPlayer(character)) {
          const hudState = this._utils.getSelect(getAllHudDisplay);

          if (hudState.dialog)
            this._store.dispatch(DialogActions.endConversation());

          if (hudState.shop) this._shopService.closeShop();

          if (hudState.container)
            this._store.dispatch(
              HudDisplayActions.hideHud({ name: 'container' }),
            );

          if (hudState.bank)
            this._store.dispatch(HudDisplayActions.hideHud({ name: 'bank' }));
        }

        return [
          CharactersActions.moveStep({
            idCharacter,
            direction: direction,
          }),
        ];
      }),
    ),
  );

  private _moveIdleStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.moveStep),
      delay(138),
      map(({ idCharacter }) => CharactersActions.idleStep({ idCharacter })),
    ),
  );

  private _teleportTo$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.idleStep),
        withLatestFrom(this._store.select(getPlayer)),
        tap(([_, { position }]) => {
          const warp: Warp = this._utils.getSelect(
            getWorldTileAt(position.x, position.y),
          ).warp;

          if (!warp) return;
          this._playerDbService
            .updatePlayer()
            .pipe(take(1))
            .subscribe(() => {
              this._store.dispatch(WorldActions.teleportTo({}));
            });
        }),
      ),
    { dispatch: false },
  );

  private _resetWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld, WorldActions.teleportTo),
      tap(() => {
        this._timersService.stopAllTimersFight();
        this._timersService.stopAllTimersMove();
      }),
      map(() => CharactersActions.resetCharacters()),
    ),
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportToSuccess),
      switchMap(({ world, warp }) => [
        CharactersActions.setPositionXY({
          idCharacter: 0,
          x: warp.position.x,
          y: warp.position.y,
        }),

        WorldActions.loadWorldSuccess({ world }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _characterService: CharacterManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _shopService: ShopManagerService,
    private readonly _store: Store,
    private readonly _timersService: TimersManagerService,
    private readonly _utils: UtilsService,
  ) {}
}
