import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { CraftsmanshipJobs } from '@t12/jobs/constants/craftsmanship-jobs.contants';
import { HobbyJobs } from '@t12/jobs/constants/hobby-jobs.contants';

export const getKindJobByCode = (jobCode: JobCode): JobKind =>
  CraftsmanshipJobs.includes(jobCode)
    ? JobKind.Craftsmanship
    : HobbyJobs.includes(jobCode)
      ? JobKind.Hobby
      : undefined;
