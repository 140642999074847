import { EventStep } from '@t12/common/events/type/event-step.type';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';

export const EVENT_FEATURE_KEY = 'event';

export interface EventState {
  progressStatus: ProgressStatus;
  indexStep: number;
  events: EventStep;
}

export const initialEventState: EventState = {
  progressStatus: ProgressStatus.NOT_DONE,
  indexStep: 0,
  events: [],
};
