import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InventoryUtilsService } from '@t12/inventory/services/inventory-utils/inventory-utils.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { BehaviorSubject } from 'rxjs';
import { ShopDbService } from '../shop-db/shop-db.service';

@Injectable({
  providedIn: 'root',
})
export class ShopManagerService {
  private _amountActiveItem: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private readonly _shopDbService: ShopDbService,
    private readonly _inventoryUtilsService: InventoryUtilsService,
    private readonly _store: Store,
  ) {}

  get amountActiveItem$(): BehaviorSubject<number> {
    return this._amountActiveItem;
  }

  // Arguments : IdItem
  // Résultat : Renvoie la quantité possédée de l'objet par le joueur
  public setActiveItemAmount(codeItem: string): void {
    this.amountActiveItem$.next(
      this._inventoryUtilsService.countItemInInventory(codeItem),
    );
  }

  // Argument : ------
  // Résultat : Ferme la fenêtre de commerce, l'inventaire et le dialog
  public closeShop(): void {
    this._store.dispatch(HudDisplayActions.hideHud({ name: 'shop' }));
    this._store.dispatch(HudDisplayActions.hideHud({ name: 'inventory' }));
  }
}
