import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Shop } from '@t12/common/shop/interfaces/shop.interface';

export const ShopActions = createActionGroup({
  source: 'SHOP',
  events: {
    Init: emptyProps(),
    'Open shop': props<{ npcCode: string }>(),
    'Open shop success': props<{ shop: Shop }>(),
    'Open shop failed': emptyProps(),
    'Set shop': props<{ shop: Shop }>(),
  },
});
