import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { ContainerState } from '@t12/container/store';

export const ContainerActions = createActionGroup({
  source: 'CONTAINER',
  events: {
    Init: emptyProps(),
    'Open container': props<{ container: IContainer }>(),
    'Open container success': props<{ container: IContainer }>(),
    'Set container': props<{ container: ContainerState; position: Position }>(),
    'Open container failed': props<{
      container: IContainer;
      position: Position;
    }>(),
    'Pick item container': props<{ index?: number }>(),
    'Pick item success': props<{ item: Item; amount: number }>(),
    'Pick item failed no item': props<{ itemCode?: string; amount?: number }>(),
    'Pick item failed not enough place': emptyProps(),

    'Remove item': props<{ itemCode: string; amount: number }>(),
    'inc current page': props<{ inc: 1 | -1 }>(),
    'Update items displayed': emptyProps(),
  },
});
