import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FadingStatus } from '@t12/utils/enums/fading-status.enum';

export const EventActions = createActionGroup({
  source: 'EVENT',
  events: {
    Init: emptyProps(),
    'set fading status': props<{ fadingStatus: FadingStatus }>(),
    'set fading status success': props<{ fadingStatus: FadingStatus }>(),
  },
});
