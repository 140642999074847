import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPlayerCreateDto } from '@t12/common/creation-player/interfaces/player-create-dto.interface';
import { CharacterCreationManagerService } from '@t12/creation-player/services/character-creation-manager/character-creation-manager.service';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import {
  getGenderPlayerCreation,
  getRaceCodePlayerCreation,
  getPlayerCreation,
} from '@t12/creation-player/store/selectors/creation-player.selectors';
import { getNamesByGender } from '@t12/utils/adapters/get-names-by-gender.adapter';
import {
  catchError,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
  forkJoin,
  mergeMap,
  tap,
} from 'rxjs';
import { CreationPlayerActions } from '../actions/creation-player.actions';

@Injectable()
export class CreationPlayerEffects {
  private _loadAllCreationItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CreationPlayerActions.loadItemsForCreation),
      switchMap(() =>
        forkJoin({
          races: this._characterCreationService.getAllRaces$().pipe(take(1)),
          stats: this._characterCreationService.getAllStats$().pipe(take(1)),
          personalities: this._characterCreationService
            .getAllPersonalities$()
            .pipe(take(1)),
          divinities: this._characterCreationService
            .getAllDivinities$()
            .pipe(take(1)),
        }).pipe(
          map(({ races, stats, personalities, divinities }) =>
            CreationPlayerActions.loadItemsForCreationSuccess({
              races,
              stats,
              personalities,
              divinities,
            }),
          ),
          catchError((error) =>
            of(CreationPlayerActions.loadItemsForCreationFailed({ error })),
          ),
        ),
      ),
    ),
  );

  private _initNames$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        CreationPlayerActions.initNames,
        CreationPlayerActions.updateActiveRace,
        CreationPlayerActions.setGenderPlayer,
      ),
      withLatestFrom(
        this._store.select(getRaceCodePlayerCreation),
        this._store.select(getGenderPlayerCreation),
      ),
      switchMap(([_, raceCode, gender]) =>
        this._characterCreationService.getRandomNamesByRace$(raceCode).pipe(
          take(1),
          map((randomNames) => {
            if (!randomNames) return CreationPlayerActions.initNamesError();

            const { firstNames, lastNames } = getNamesByGender(
              randomNames,
              gender,
            );

            return CreationPlayerActions.initNamesSuccess({
              firstNames,
              lastNames,
            });
          }),
          catchError(() => of(CreationPlayerActions.initNamesError())),
        ),
      ),
    ),
  );

  private _createCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CreationPlayerActions.createCharacter),
      withLatestFrom(this._store.select(getPlayerCreation)),
      mergeMap(([_, player]) => {
        if (!this._authService.isLoggedIn())
          return of(
            CreationPlayerActions.createCharacterFailure({
              error: 'User not logged in',
            }),
          );

        const createPlayerDto: IPlayerCreateDto = {
          name: this._characterCreationService.formatName(player.name),
          gender: player.gender,
          ...player.stats,
          skin: player.skin,
          raceCode: player.raceCode,
          personalitiesCode: player.personalities?.map(
            (element) => element.code,
          ),
        };

        return this._characterCreationService
          .addPlayerCharacter(createPlayerDto)
          .pipe(
            map(() => CreationPlayerActions.createCharacterSuccess()),
            catchError(() =>
              of(
                CreationPlayerActions.createCharacterFailure({
                  error: 'Échec de création',
                }),
              ),
            ),
          );
      }),
    ),
  );

  private _navigateOnSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CreationPlayerActions.createCharacterSuccess),
        tap(() => this._router.navigateByUrl('/characters')),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _authService: AuthenticationService,
    private readonly _characterCreationService: CharacterCreationManagerService,
    private readonly _router: Router,
    private readonly _store: Store,
  ) {}
}
