import { Injectable } from '@angular/core';
import { countItem } from '@t12/common/inventory/functions/count-item/count-item.function';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';

@Injectable({
  providedIn: 'root',
})
export class InventoryUtilsService {
  constructor(private readonly _utils: UtilsService) {}

  // Argument : ID Objet à compter
  // Résultat : Retourne le nombre d'objets présent dans l'inventaire du joueur
  public countItemInInventory(code: string): number {
    const inventory = this._utils.getSelect(getPlayerInventory);

    return countItem(code, inventory);
  }

  public getFreeInventorySlotAmount(item: Item): number {
    const inventory = this._utils.getSelect(getPlayerInventory);

    return inventory
      .filter((slotItem) => slotItem.code === item.code || !slotItem.code)
      .reduce((total, slot) => total + (item.max - slot.amount), 0);
  }
}
