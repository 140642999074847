import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { ILootItemDto } from '@t12/common/container/interfaces/loot-dto.interface';
import { getContainerKind } from '@t12/container/store/selectors/container.selectors';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContainerDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  getContainer(containerKind: ContainerKind): Observable<IContainer> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(
      `${environment(`container/${containerKind}/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<IContainer>;
  }

  pickContainerItem(itemCode: string) {
    const playerId = this._utilsService.getSelect(getPlayerID);
    const containerKind = this._utilsService.getSelect(getContainerKind);

    const pickItemDto: ILootItemDto = {
      playerId,
      itemCode,
      containerKind,
    };

    return this._http.patch(
      `${environment(`container/pick`).uri}`,
      pickItemDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
