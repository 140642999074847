import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestState } from '@t12/quest/store';

const selectRoot = createFeatureSelector<QuestState>('quest');

export const getQuestsInfos = createSelector(
  selectRoot,
  ({ quests }: QuestState) => quests,
);

export const getActiveGoal = createSelector(
  selectRoot,
  ({ activeGoal }: QuestState) => activeGoal,
);
