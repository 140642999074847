import { createReducer, on } from '@ngrx/store';
import { QuestGoal } from '@t12/common/quest/interfaces/quest-goal.inferface';
import { initialQuestState, QuestState } from '@t12/quest/store';
import { QuestActions } from '../actions/quest.actions';

export const QuestReducer = createReducer(
  initialQuestState,

  on(QuestActions.init, () => initialQuestState),

  on(QuestActions.setQuests, (questState: QuestState, { questsInfos }) => ({
    ...questState,
    quests: questsInfos,
  })),

  on(QuestActions.addQuest, (questState: QuestState, { questInfos }) => ({
    ...questState,
    quests: [...questState.quests, questInfos],
  })),

  on(QuestActions.removeQuest, (questState: QuestState, { questCode }) => ({
    ...questState,
    quests: questState.quests.filter((item) => item.code !== questCode),
  })),

  on(
    QuestActions.updateGoal,
    (questState: QuestState, { goal, amount: amountAdded }) => {
      let activeGoal: QuestGoal;

      return {
        ...questState,
        quests: questState.quests.map((questInfo) => {
          const goals = questInfo.goals.map((goalItem) => {
            const {
              entityCode,
              kind,
              amount: currentAmount,
              amountTotal,
            } = goalItem;

            let amount = currentAmount;
            if (entityCode === goal.entityCode && kind === goal.kind) {
              amount = Math.min(currentAmount + amountAdded, amountTotal);
              activeGoal = { ...goalItem, amount };
            }

            return {
              ...goalItem,
              amount,
            };
          });
          return { ...questInfo, goals };
        }),
        activeGoal,
      };
    },
  ),
);
