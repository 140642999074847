import { createReducer, on } from '@ngrx/store';
import { EventActions } from '../actions/event.actions';
import { initialEventState, EventState } from '../index';

export const EventReducer = createReducer(
  initialEventState,

  on(EventActions.init, () => ({
    ...initialEventState,
  })),

  on(
    EventActions.setFadingStatus,
    (eventState: EventState, { fadingStatus }) => ({
      ...eventState,
      fadingStatus,
    }),
  ),
);
