import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '@t12/environment';
import { TokenPayload, TokenResponse } from '@t12/login/interfaces/userdetails';
import { UserState } from '@t12/login/store';
import { UserActions } from '@t12/login/store/actions/user.actions';
import { LocalService } from '@t12/utils/services/local/local.service';
import { Observable, Subscription, take } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _localService: LocalService,
    private readonly _router: Router,
    private readonly _store: Store,
  ) {}

  // Arguments : ------
  // Résultat : Renvoie les informations du compte connecté stocké dans le token
  // TODO Double call getUserDetails ??
  public getUserDetails(): UserState {
    const token = this.getToken();
    let payload;

    if (token) {
      payload = window.atob(token.split('.')[1]);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  // Arguments : ------
  // Résultat : Vérifie que la connexion n'est pas expirée.
  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    const delay = 1000;
    if (user?.exp > Date.now() / delay) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  // Arguments : Token
  // Résultat : Crée un compte avec le token reçu
  public register$(user: TokenPayload): Observable<any> {
    return this._request$('post', 'register', user);
  }

  // Arguments : Token
  // Résultat : Connexion avec le token reçu
  public login$(user: TokenPayload): Observable<any> {
    return this._request$('post', 'login', user);
  }

  // Arguments : ------
  // Résultat : Renvoie le token de connexion si existe
  public getToken(): string {
    return this._localService.getJsonValue('t12-token');
  }

  // Arguments : ------
  // Résultat : Déconnecte l'utilisateur actif et supprime le token du localStorage
  public logout(): void {
    this._localService.clearToken();
    this._store.dispatch(UserActions.logout());
    this._router.navigateByUrl('/');
  }

  // Arguments : TokenPayload
  // Résultat : Supprime le compte actif
  public deleteAccount(): Subscription {
    return this._http
      .delete(`${environment(`user`).uri}`, {
        headers: { authorization: `Bearer ${this.getToken()}` },
      })
      .pipe(take(1))
      .subscribe();
  }

  // Arguments : TokenPayload
  // Résultat : Met à jour le compte renseigné
  // TODO Distinguer update individuel patch
  public updateUserDetails$(user: TokenPayload): Observable<any> {
    return this._request$('patch', 'update', user);
  }

  // Arguments : Méthode d'envoi de données, le type de requête et un token si existant.
  // Résultat : Exécute la requête passée en argument
  private _request$(
    method: 'post' | 'get' | 'patch',
    type: 'login' | 'register' | 'update',
    user?: TokenPayload,
  ): Observable<TokenResponse> {
    let base;
    if (method === 'post') {
      base = this._http.post(`${environment(`user/${type}`).uri}`, user, {
        headers: { authorization: `Bearer ${this.getToken()}` },
      });
    } else if (method === 'patch') {
      base = this._http.patch(`${environment(`user/${type}`).uri}`, user, {
        headers: { authorization: `Bearer ${this.getToken()}` },
      });
    }

    return base.pipe(
      map((data: TokenResponse) => {
        const { access_token: token } = data;
        if (typeof token === 'string') {
          this._localService.setJsonValue('t12-token', JSON.stringify(token));
        }
        return data;
      }),
    );
  }
}
