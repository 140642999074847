import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getItemsBank } from '@t12/bank/store/selectors/bank.selectors';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';

@Injectable({
  providedIn: 'root',
})
export class ItemDepositService {
  constructor(
    private readonly _notificationService: NotificationManagerService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : Objet à ajouter, quantité à ajouter
  // Résultat : Ajoute un objet à l'inventaire
  public addItemInto(
    containerKind: ContainerKind,
    item: Item,
    amount: number = item.amount,
  ) {
    const { code: originalCode } = item;
    const container = this._getContainer(containerKind);
    const prefixAmount = containerKind === ContainerKind.INVENTORY ? '+' : '-';
    this._notificationService.addNotification(
      'validation',
      `${prefixAmount}${amount} ${item.name}`,
      5000,
      item.img,
    );

    let updatedContainer = container.map((slotItem) => {
      if (slotItem.code === originalCode && amount) {
        const spaceLeft = slotItem.max - slotItem.amount;
        const amountToAddToItem = Math.min(spaceLeft, amount);

        slotItem.amount += amountToAddToItem;
        amount -= amountToAddToItem;
      } else if (slotItem.code === undefined && amount > 0) {
        const availableSpace = Math.min(item.max, amount);

        amount -= availableSpace;
        slotItem = { ...item, amount: availableSpace };
      }

      return slotItem;
    });

    if (containerKind === ContainerKind.BANK && amount > 0) {
      while (amount > 0) {
        const amountAdded = Math.min(item.max, amount);
        amount -= amountAdded;
        updatedContainer.push({ ...item, amount: amountAdded });
      }
    }

    this._setContainer(containerKind, updatedContainer);

    this._questGoalsService.updateCollectGoals();
  }

  // Argument : Code l'objet à retirer, quantité à retirer
  // Résultat : Retire un objet à l'inventaire
  public removeItemFrom(
    containerKind: ContainerKind,
    itemCode: string,
    amount: number = 1,
  ) {
    const container = this._getContainer(containerKind);

    let updatedContainer = container.reverse().map((item) => {
      if (item.code === itemCode && amount > 0) {
        const removedAmount = Math.min(amount, item.amount);
        item.amount -= removedAmount;
        amount -= removedAmount;
        return item.amount === 0 ? ({ amount: 0 } as Item) : item;
      }
      return item;
    });

    this._setContainer(containerKind, updatedContainer);
    this._questGoalsService.updateCollectGoals();
  }

  private _getContainer(containerKind: ContainerKind): Item[] {
    switch (containerKind) {
      case ContainerKind.INVENTORY:
        return this._utils.getSelect(getPlayerInventory);
      case ContainerKind.BANK:
        return this._utils.getSelect(getItemsBank);
    }
  }

  private _setContainer(containerKind: ContainerKind, items: Item[]): void {
    switch (containerKind) {
      case ContainerKind.BANK:
        break;
    }
  }
}
