import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { StatsCharacter } from '@t12/common/characters/interfaces/stats-character.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { PlayerJobs } from '@t12/common/job/interfaces/player-jobs.interface';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { HitKind } from '@t12/fight/types/hit-kind.type';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    Reset: emptyProps(),
    'Load player': emptyProps(),
    'Load player success': props<{ player: Player }>(),
    'Init player success': emptyProps(),
    'Load player failed': props<{ error: HttpErrorResponse }>(),

    'Set characters': props<{ characters: (Monster | NPC)[] }>(),
    'Set characters success': emptyProps(),
    'Reset characters': emptyProps(),

    Move: props<{ idCharacter: number; direction: Looking }>(),
    'Move step': props<{ idCharacter: number; direction: Looking }>(),
    'Idle step': props<{ idCharacter: number }>(),

    Attack: props<{ idCharacter: number; attackKind: AttackKind }>(),
    'Attack first step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),
    'Attack second step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),
    'Attack third step': props<{
      idCharacter: number;
      attackKind: AttackKind;
    }>(),

    'Attack try to hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
    }>(),
    'Attack try to hit failed': props<{
      attackKind: AttackKind;
      hit: HitKind;
    }>(),

    'Attack hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
      hit: HitKind;
    }>(),
    'Attack deal damage': props<{
      attacker: Character;
      target: Character;
      damage: number;
    }>(),
    'add fight': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),

    'npc death': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),
    'npc death success': props<{
      attacker: Character;
      target: NPC | Monster;
    }>(),

    'Set player track id': props<{ trackId: number }>(),
    'Set position x y': props<{ idCharacter: number; y: number; x: number }>(),
    'Set looking': props<{ idCharacter: number; looking: Looking }>(),
    'Add sprite x': props<{ idCharacter: number; spriteX: number }>(),
    'Add sprite y': props<{ idCharacter: number; spriteY: number }>(),
    'Set sprite x': props<{ idCharacter: number; spriteX: number }>(),
    'Set sprite Y': props<{ idCharacter: number; spriteY: number }>(),
    'Set face to player': props<{ idCharacter: number }>(),
    'Set can move': props<{ idCharacter: number; canMove: boolean }>(),
    'Add health': props<{ idCharacter: number; health: number }>(),
    'Set health': props<{ idCharacter: number; health: number }>(),
    'Add mana': props<{ idCharacter: number; mana: number }>(),
    'Set mana': props<{ idCharacter: number; mana: number }>(),
    'Add health mana': props<{
      idCharacter: number;
      health: number;
      mana: number;
    }>(),

    'Player death': emptyProps(),
    'Apply death sanction': emptyProps(),

    'Set stats': props<{ idCharacter: number; stats: StatsCharacter }>(),

    'Reward fight': props<{
      target: NPC | Monster;
      xp: number;
      container: IContainer;
    }>(),
    'Reward xp': props<{ xp: number }>(),
    'Reward loot': props<{ container: IContainer }>(),
    'Reward quest kill': props<{ targetCode: string }>(),
    'Add xp': props<{ xp: number }>(),
    'Level up': props<{ xp: number }>(),

    'Add gold': props<{ gold: number }>(),
    'Set state': props<{ idCharacter: number; state: 'fight' | 'passive' }>(),

    'Equip item': props<{ item: Item }>(),
    'Unequip item': props<{ item: Item }>(),
    'Set equipments': props<{
      idCharacter: number;
      equipments: Item[];
    }>(),
    'Set have quest': props<{ idCharacter: number; hasQuest: boolean }>(),
    'Set is done quest': props<{ idCharacter: number; isDone: boolean }>(),
    'Add new job': props<{
      idCharacter: number;
      job: PlayerJob;
    }>(),
    'Remove job': props<{
      idCharacter: number;
      job: PlayerJob;
    }>(),
    'Set jobs': props<{
      idCharacter: number;
      jobs: PlayerJobs;
    }>(),
    'Set active job xp': props<{
      idCharacter: number;
      kind: JobKind;
      index: number;
      xp: number;
    }>(),
  },
});
