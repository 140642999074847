import { BANK_FEATURE_KEY } from '@t12/bank/store';
import { BankReducer } from '@t12/bank/store/reducers/bank.reducer';
import { PLAYER_FEATURE_KEY } from '@t12/characters/store';
import { CharactersReducer } from '@t12/characters/store/reducers/characters.reducer';
import { CHAT_FEATURE_KEY } from '@t12/chat/store';
import { ChatReducer } from '@t12/chat/store/reducers/chat.reducer';
import { CONTAINER_FEATURE_KEY } from '@t12/container/store';
import { ContainerReducer } from '@t12/container/store/reducers/container.reducer';
import { CREATION_PLAYER_FEATURE_KEY } from '@t12/creation-player/store';
import { CreationPlayerReducer } from '@t12/creation-player/store/reducers/creation-player.reducer';
import { DIALOG_FEATURE_KEY } from '@t12/dialog/store';
import { DialogReducer } from '@t12/dialog/store/reducers/dialog.reducer';
import { JOB_FEATURE_KEY } from '@t12/jobs/store';
import { JobReducer } from '@t12/jobs/store/reducers/job.reducer';
import { USER_FEATURE_KEY } from '@t12/login/store';
import { UserReducer } from '@t12/login/store/reducers/user.reducer';
import { HUD_DISPLAY_FEATURE_KEY } from '@t12/overlay/store';
import { HudDisplayReducer } from '@t12/overlay/store/reducers/hud-display.reducer';
import { QUEST_FEATURE_KEY } from '@t12/quest/store';
import { QuestReducer } from '@t12/quest/store/reducers/quest.reducer';
import { SHOP_FEATURE_KEY } from '@t12/shop/store';
import { ShopReducer } from '@t12/shop/store/reducers/shop.reducer';
import { HOTKEY_FEATURE_KEY } from '@t12/utils/store/hotkey';
import { HotkeyReducer } from '@t12/utils/store/hotkey/reducers/hotkey.reducer';
import { WORLD_FEATURE_KEY } from '@t12/world/store';
import { WorldReducer } from '@t12/world/store/reducers/world.reducer';
import { EVENT_FEATURE_KEY } from './events/store';
import { EventReducer } from './events/store/reducers/event.reducer';
import { INVENTORY_FEATURE_KEY } from '@t12/inventory/store';
import { InventoryReducer } from '@t12/inventory/store/reducers/inventory.reducer';

export const storeReducers = {
  [USER_FEATURE_KEY]: UserReducer,
  [PLAYER_FEATURE_KEY]: CharactersReducer,
  [INVENTORY_FEATURE_KEY]: InventoryReducer,
  [HOTKEY_FEATURE_KEY]: HotkeyReducer,
  [HUD_DISPLAY_FEATURE_KEY]: HudDisplayReducer,
  [DIALOG_FEATURE_KEY]: DialogReducer,
  [CREATION_PLAYER_FEATURE_KEY]: CreationPlayerReducer,
  [SHOP_FEATURE_KEY]: ShopReducer,
  [CHAT_FEATURE_KEY]: ChatReducer,
  [QUEST_FEATURE_KEY]: QuestReducer,
  [BANK_FEATURE_KEY]: BankReducer,
  [JOB_FEATURE_KEY]: JobReducer,
  [CONTAINER_FEATURE_KEY]: ContainerReducer,
  [EVENT_FEATURE_KEY]: EventReducer,
  [WORLD_FEATURE_KEY]: WorldReducer,
};

export const storeOptions = {
  runtimeChecks: {
    strictActionTypeUniqueness: true,
    strictActionImmutability: true,
    strictStateImmutability: true,
  },
};
