import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/login/services/auth/authentication.service';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : ID dialog
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getDialog(code: string): Observable<TextDialog[]> {
    const playerId = this._utils.getSelect(getPlayerID);
    return this._http.get(
      `${environment(`dialog/${code}/player/${playerId}`).uri}`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<TextDialog[]>;
  }

  // Argument : ID dialog
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getDialogQuest(
    questCode: string,
    npcCode: string,
  ): Observable<TextDialog[]> {
    const playerId = this._utils.getSelect(getPlayerID);
    return this._http.get(
      `${
        environment(`dialog/${questCode}/npc/${npcCode}/player/${playerId}`).uri
      }`,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    ) as Observable<TextDialog[]>;
  }
}
