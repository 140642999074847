import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayerPositionInFront,
  getPlayerJobWithCode,
} from '@t12/characters/store/selectors/characters.selectors';
import { getOpeningSoundContainer } from '@t12/container/constants/get-opening-sound-containter.cnstant';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { HarvestDbService } from '@t12/jobs/services/harvest-db/harvest-db.service';
import { JobManagerService } from '@t12/jobs/services/job-manager/job-manager.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudContainer } from '@t12/overlay/store/selectors/hud-display.selectors';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  concatMap,
  map,
  withLatestFrom,
  switchMap,
  tap,
  take,
  catchError,
  of,
  filter,
} from 'rxjs';

@Injectable()
export class JobsEffects {
  private _harvest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvest),
      withLatestFrom(this._store.select(getHudContainer)),
      filter(([{ harvestPoint }]) =>
        this._jobService.canInteractEntityJob(
          harvestPoint.jobCode,
          harvestPoint.requiredXp,
        ),
      ),
      map(([{ harvestPoint }, isVisibleLootBox]) =>
        isVisibleLootBox
          ? ContainerActions.pickItemContainer({})
          : JobActions.harvestSuccess({ harvestPoint }),
      ),
    ),
  );

  private _harvestSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvestSuccess),
      tap(({ harvestPoint }) => {
        const openSong = getOpeningSoundContainer(harvestPoint.material);
        this._audioService.playSound('miscs', openSong, 'ogg');
      }),
      withLatestFrom(this._store.select(getPlayerPositionInFront)),
      switchMap(([{ harvestPoint }, position]) =>
        this._harvestDb.getHarvestRessources().pipe(
          take(1),
          concatMap(({ container, playerJobXp }) => {
            const actions = [
              ContainerActions.setContainer({ container, position }),
              HudDisplayActions.showHud({ name: 'container' }),
              playerJobXp
                ? JobActions.updateJobXP({
                    jobCode: harvestPoint.jobCode,
                    playerJobXp,
                  })
                : null,
            ];

            return actions.filter((action) => action !== null);
          }),
          catchError(() => of(JobActions.harvestFailed({ position }))),
        ),
      ),
    ),
  );

  private _updateJobXp$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.updateJobXP),
        switchMap(({ jobCode, playerJobXp: xp }) =>
          this._store.select(getPlayerJobWithCode(jobCode)).pipe(
            tap((playerJob) => {
              this._notificationService.addNotification(
                'job',
                `Vous avez gagné 1XP pour le métier : ${playerJob.name}`,
              );
            }),
            map((playerJob) => {
              return CharactersActions.setActiveJobXp({
                idCharacter: 0,
                kind: playerJob.kind,
                index: 0,
                xp,
              });
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _harvestFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.harvestFailed),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          'Ce point de récolte a expiré.',
        );
      }),
      map(({ position: { x, y } }) => {
        return WorldActions.removeEntity({ x, y, entity: 'harvestPoint' });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _harvestDb: HarvestDbService,
    private readonly _jobService: JobManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
