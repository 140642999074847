import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { UserActions } from '@t12/login/store/actions/user.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { delayRespawnPlayer } from '@t12/player/constants/delay-respawn-player.constant';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { QuestDbService } from '@t12/quest/services/quest-db/quest-db.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { LocalService } from '@t12/utils/services/local/local.service';
import { HotkeyActions } from '@t12/utils/store/hotkey/actions/hotkey.actions';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';

dayjs.locale('fr');

@Injectable()
export class PlayerCharacterEffects {
  private _loadPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayer),
      switchMap(() =>
        this._playerDbService.getPlayerCharacter$().pipe(
          take(1),
          map((player) => CharactersActions.loadPlayerSuccess({ player })),
          catchError((error) =>
            of(CharactersActions.loadPlayerFailed({ error })),
          ),
        ),
      ),
    ),
  );

  private _loadPlayerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayerSuccess),
      switchMap(({ player }) => {
        const hotkeys = this._localService.getJsonValue(`hotkeys${player.id}`);
        if (hotkeys)
          this._store.dispatch(HotkeyActions.setHotkeys({ hotkeys }));

        const date = dayjs(player.lastConnexion);
        const hour = date.format('HH:mm');
        const day = date.format('DD MMMM YYYY');

        this._chatService.addChatLog(
          'chat',
          '',
          `Dernière connexion à ${hour}, le ${day}.`,
          ChatLogKind.Log,
        );

        if (!player.lastConnexion)
          this._store.dispatch(
            HudDisplayActions.showHud({ name: 'presentation' }),
          );

        return [
          CharactersActions.initPlayerSuccess(),
          WorldActions.loadWorld(),
          InventoryActions.setInventory({ items: player.inventory }),
          CharactersActions.setPlayerTrackId({
            trackId: this._worldService.getTrackId(),
          }),
        ];
      }),
    ),
  );

  private _initPlayerQuests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.initPlayerSuccess),
      switchMap(() =>
        this._questDbService.getQuestsPlayer().pipe(
          take(1),
          map((questsInfos) => QuestActions.setQuests({ questsInfos })),
        ),
      ),
    ),
  );

  private _levelUp$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.levelUp),
        withLatestFrom(this._store.select(getPlayer)),
        tap(([_, player]) => {
          this._audioService.playSound('miscs', 'lvlup', 'wav');
          this._notificationService.addNotification(
            'item',
            `Vous venez de gagner un niveau ! (Lvl ${player.lvl})`,
          );
          this._chatService.addChatLog(
            'fight',
            player.name,
            `a gagné un niveau ! (Lvl ${player.lvl})`,
            ChatLogKind.Bonus,
          );
        }),
      ),
    { dispatch: false },
  );

  private _loadPlayerSuccessDead$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.loadPlayerSuccess),
        withLatestFrom(this._store.select(getPlayer)),
        filter(([_, player]) => player.dead),
        tap((_) => {
          this._playerDbService
            .updatePlayer()
            .pipe(take(1))
            .subscribe(() => {
              this._store.dispatch(CharactersActions.playerDeath());
            });
        }),
      ),
    { dispatch: false },
  );

  private _playerDeath$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.playerDeath),
      delay(delayRespawnPlayer),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([_, player]) => {
        const goldLoose = -Math.ceil(player.gold / 2);
        const xpLoose = -Math.ceil(player.xp / 2);
        this._chatService.addChatLog(
          'fight',
          player.name,
          `est mort et a perdu  ${goldLoose} OR et ${xpLoose} points d'expériences`,
          ChatLogKind.Malus,
        );
      }),
      switchMap(() =>
        this._playerDbService.playerDeath().pipe(
          take(1),
          mergeMap(() => [
            CharactersActions.applyDeathSanction(),
            WorldActions.loadWorld(),
          ]),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  // TODO Ajouter la fusion de slots côté back
  private _orderInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        InventoryActions.addItemInInventory,
        InventoryActions.removeItemInInventory,
        InventoryActions.setInventory,
      ),
      map(() => InventoryActions.orderInventory()),
    ),
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportToSuccess),
      map(() =>
        CharactersActions.setLooking({ idCharacter: 0, looking: 'down' }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _localService: LocalService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _questDbService: QuestDbService,
    private readonly _store: Store,
    private readonly _worldService: WorldGeneratorService,
  ) {}
}
