import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayer,
  getCharacterById,
  getCharacterInFront,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { QuestGoalsService } from '@t12/quest/services/quest-goals/quest-goals.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  forkJoin,
  filter,
} from 'rxjs';

@Injectable()
export class FightCharacterEffects {
  private _addFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.addFight),
      switchMap((action) =>
        forkJoin({
          attacker: this._store
            .select(getCharacterById(action.attacker.idCharacter))
            .pipe(take(1)),
          target: this._store
            .select(getCharacterInFront(action.attacker.idCharacter))
            .pipe(take(1)),
        }).pipe(map(({ attacker, target }) => ({ attacker, target, action }))),
      ),
      filter(({ target, attacker }) => !attacker.dead && !target.dead),
      map(({ attacker, target }) => {
        this._activeTargetService.setActiveTarget(target);

        if (isNPCOrMonster(target)) {
          this._timersService.stopTimerMoveByID(target.idCharacter);
          this._characterService.createTurnFightInterval(attacker, target);
        }

        return CharactersActions.setFaceToPlayer({
          idCharacter: target.idCharacter,
        });
      }),
    ),
  );

  // TODO Call action si xp, container defined
  private _rewardFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardFight),
      switchMap(({ target, xp, container }) => {
        return [
          CharactersActions.rewardXp({ xp }),
          CharactersActions.rewardLoot({ container }),
          CharactersActions.rewardQuestKill({ targetCode: target.code }),
        ];
      }),
    ),
  );

  private _rewardXp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardXp),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([{ xp }, { name: playerName }]) => {
        this._notificationService.addNotification('item', `+${xp} XP`);
        this._chatService.addChatLog(
          'fight',
          playerName,
          `a gagné ${xp} points d'expériences`,
          ChatLogKind.Bonus,
        );
      }),
      map(([action, player]) => {
        const newXp = player.xp + action.xp;

        if (newXp >= calculateXpForNextLevel(player.lvl)) {
          return CharactersActions.levelUp({ xp: action.xp });
        } else {
          return CharactersActions.addXp({ xp: action.xp });
        }
      }),
    ),
  );

  private _rewardLoot$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardLoot),
      filter(({ container }) => container?.items.length > 0),
      map(({ container }) => {
        const { x, y } = container.position;

        return WorldActions.addContainerTile({
          x,
          y,
          container,
        });
      }),
    ),
  );

  private _rewardQuestKill$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardQuestKill),
      withLatestFrom(this._store.select(getPlayer)),
      map(([{ targetCode }]) =>
        this._questGoalsService.findGoalFromPlayerQuest(targetCode, 'kill'),
      ),
      filter((activeGoalQuest) => !!activeGoalQuest),
      map((activeGoalQuest) => {
        return QuestActions.updateGoal({ goal: activeGoalQuest, amount: 1 });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _characterService: CharacterManagerService,
    private readonly _chatService: ChatManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
    private readonly _timersService: TimersManagerService,
  ) {}
}
