import { createFeatureSelector, createSelector } from '@ngrx/store';
import { nbItemsPageBank } from '@t12/bank/constants/nb-items-page-bank.constant';
import { BankState } from '@t12/bank/store';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { ItemQuality } from '@t12/common/item/enums/item-quality.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';

const selectRoot = createFeatureSelector<BankState>('bank');

export const getBank = createSelector(selectRoot, (bank: BankState) => bank);

export const getLvlBank = createSelector(selectRoot, ({ lvl }) => lvl);

export const getBankGold = createSelector(selectRoot, ({ gold }) => gold);

export const getItemsBank = createSelector(selectRoot, ({ items }) => items);

export const getDisplayedItemsBank = createSelector(
  selectRoot,
  ({ items, currentPage }) => {
    const slicedItems = items.slice(
      (currentPage - 1) * nbItemsPageBank,
      currentPage * nbItemsPageBank,
    );

    return slicedItems.concat(
      Array(nbItemsPageBank - slicedItems.length).fill({
        amount: 0,
        quality: ItemQuality.Common,
      }),
    ) as Item[];
  },
);

export const getBankTab = createSelector(selectRoot, ({ tab }) => tab);

export const getCurrentPageBank = createSelector(
  selectRoot,
  ({ currentPage }) => currentPage,
);

export const getMaxPageBank = createSelector(
  selectRoot,
  ({ maxPage }) => maxPage,
);

export const getDepositItem = createSelector(
  selectRoot,
  ({ depositItem }) => depositItem,
);

export const getDepositItemAmount = createSelector(
  selectRoot,
  ({ depositItem }) => depositItem.amount,
);

export const getDepositGold = createSelector(
  selectRoot,
  ({ depositGold }) => depositGold,
);

export const getFreeBankSlotAmount = (item: Item) =>
  createSelector(getItemsBank, (bank: Item[]) => {
    const availableSlots = bank.filter(
      (slotItem) => slotItem.code === item.code,
    );
    const nonAvailableSlots = bank.filter(
      (slotItem) => slotItem.code !== item.code,
    ).length;
    const emptySlots =
      bankMaxSize - (availableSlots.length + nonAvailableSlots);

    const filledCapacity = availableSlots.reduce(
      (total, slot) => total + (item.max - slot.amount),
      0,
    );

    return filledCapacity + emptySlots * item.max;
  });

export const getCountItemBank = (itemCode: string) =>
  createSelector(getItemsBank, (bank) => {
    return (
      bank
        ?.filter((item) => item.code === itemCode)
        .reduce((total, item) => total + item.amount, 0) || 0
    );
  });
