import { createReducer, on } from '@ngrx/store';
import { BankTab } from '@t12/bank/components/bank/bank.component';
import { nbItemsPageBank } from '@t12/bank/constants/nb-items-page-bank.constant';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { bankSortItems } from '@t12/common/bank/sort-items/bank-sort-items.function';
import { BankState, initialBankState } from '../index';

export const BankReducer = createReducer(
  initialBankState,

  on(BankActions.init, () => ({
    ...initialBankState,
  })),

  on(BankActions.openBankSuccess, (bankState: BankState, { bank }) => ({
    ...initialBankState,
    ...bank,
    tab: BankTab.Inventory,
  })),

  on(BankActions.depositToSuccess, (bankState: BankState) => ({
    ...bankState,
  })),

  on(BankActions.depositToItemSuccess, (bankState: BankState) => {
    const { depositItem } = bankState;
    let remainingAmount = depositItem.amount;

    const updatedBank = [
      ...bankState.items.map((slot) => {
        if (remainingAmount > 0 && slot?.code === depositItem.code) {
          const amountToAdd = Math.min(
            depositItem.max - slot.amount,
            remainingAmount,
          );

          remainingAmount -= amountToAdd;
          return { ...slot, amount: slot.amount + amountToAdd };
        }
        return slot;
      }),
    ];

    updatedBank.forEach((slot, index) => {
      if (remainingAmount > 0 && updatedBank.length < bankMaxSize) {
        const amountToAdd = Math.min(depositItem.max, remainingAmount);
        updatedBank.push({ ...depositItem, amount: amountToAdd });
        remainingAmount -= amountToAdd;
      }
    });

    return {
      ...bankState,
      items: updatedBank.filter((slot) => slot.code).sort(bankSortItems),
      depositItem: {
        ...depositItem,
        amount: bankState.activeItemAmountMax - depositItem.amount,
      },
      activeItemAmountMax: bankState.activeItemAmountMax - depositItem.amount,
    };
  }),

  on(BankActions.depositFromItemSuccess, (bankState: BankState) => {
    const { depositItem } = bankState;
    let remainingAmount = depositItem.amount;

    const updatedBank = bankState.items
      .map((slot) => {
        if (remainingAmount > 0 && slot.code === depositItem.code) {
          const amountToWithdraw = Math.min(slot.amount, remainingAmount);
          remainingAmount -= amountToWithdraw;
          const newAmount = slot.amount - amountToWithdraw;

          return newAmount > 0 ? { ...slot, amount: newAmount } : null;
        }
        return slot;
      })
      .filter(Boolean);

    return {
      ...bankState,
      items: updatedBank.sort(bankSortItems),
      depositItem: {
        ...depositItem,
        amount: bankState.activeItemAmountMax - depositItem.amount,
      },
      activeItemAmountMax: bankState.activeItemAmountMax - depositItem.amount,
    };
  }),

  on(BankActions.updateItemsDisplayed, (bankState: BankState) => {
    const currentPage = Math.min(bankState.currentPage, bankMaxSize);
    const startIndex = (currentPage - 1) * nbItemsPageBank;

    return {
      ...bankState,
      displayedItems: bankState.items
        .slice(startIndex, startIndex + nbItemsPageBank)
        .sort(bankSortItems),
      maxPage: bankMaxSize / nbItemsPageBank,
      currentPage,
    };
  }),

  on(BankActions.setTab, (bankState: BankState, { tab }) => ({
    ...bankState,
    tab,
    depositItem: initialBankState.depositItem,
    activeItemAmountMax: undefined,
  })),

  on(BankActions.incCurrentPage, (bankState: BankState, { inc }) => {
    const newPage = Math.min(
      Math.max(bankState.currentPage + inc, 0),
      bankState.maxPage,
    );

    return {
      ...bankState,
      currentPage: newPage,
    };
  }),

  on(BankActions.setDepositItem, (bankState: BankState, { item }) => ({
    ...bankState,
    depositItem: item,
  })),

  on(
    BankActions.setDepositItemAmountMax,
    (bankState: BankState, { amountMax }) => ({
      ...bankState,
      activeItemAmountMax: amountMax,
    }),
  ),

  on(BankActions.resetDepositItem, (bankState: BankState) => ({
    ...bankState,
    depositItem: initialBankState.depositItem,
    activeItemAmountMax: undefined,
  })),

  on(BankActions.incDepositItemAmount, (bankState: BankState, { inc }) => ({
    ...bankState,
    depositItem: {
      ...bankState.depositItem,
      amount: Math.max(
        0,
        Math.min(
          (bankState.depositItem.amount || 1) + inc,
          bankState.activeItemAmountMax,
        ),
      ),
    },
  })),

  on(BankActions.setDepositGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    depositGold: gold,
  })),

  on(BankActions.depositToGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    gold: bankState.gold + gold,
    depositGold: 0,
  })),

  on(BankActions.depositFromGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    gold: bankState.gold - gold,
    depositGold: 0,
  })),
);
