import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PopOverManagerService } from '@t12/overlay/services/pop-over/pop-over-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { switchMap, tap } from 'rxjs';
import { HudDisplayActions } from '../actions/hud-display.actions';
import { HudDisplayState } from '../index';
import { getAllHudDisplay } from '../selectors/hud-display.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';

const noSoundHud: Array<keyof HudDisplayState> = [
  'notification',
  'dialog',
  'container',
  'inventory',
  'shop',
  'barInfos',
];

@Injectable()
export class HudDisplayEffects {
  private displayHud$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          HudDisplayActions.showHud,
          HudDisplayActions.toggleHud,
          HudDisplayActions.hideHud,
        ),
        tap(({ name: hudName }) => {
          const displayHud = this._utils.getSelect(getAllHudDisplay)[hudName];
          this._handleDisplay(displayHud, hudName);
        }),
      ),
    { dispatch: false },
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportToSuccess),
      switchMap(() => {
        return [
          HudDisplayActions.hideHud({ name: 'shop' }),
          HudDisplayActions.hideHud({ name: 'inventory' }),
          HudDisplayActions.hideHud({ name: 'dialog' }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  private _handleDisplay(
    display: boolean,
    hudName: keyof HudDisplayState,
  ): void {
    if (display) {
      if (!noSoundHud.includes(hudName)) {
        this._audioService.playSound('interfaces/buttons', 'correct');
      }

      if (hudName === 'inventory') {
        this._store.dispatch(InventoryActions.resetActiveItem());
      } else if (hudName === 'container') {
        this._store.dispatch(HudDisplayActions.showHud({ name: 'inventory' }));
      }
    } else {
      if (hudName === 'playerInfos') {
        this._popoverService.deletePopOver();
      } else if (hudName === 'container') {
        this._popoverService.deletePopOver();
        this._store.dispatch(HudDisplayActions.hideHud({ name: 'inventory' }));
      }
    }
  }
}
