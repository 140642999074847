import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { ShopDbService } from '@t12/shop/services/shop-db/shop-db.service';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import { switchMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class ShopEffects {
  private _openShop$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.openShop),
      switchMap(({ npcCode }) =>
        this._shopDbService.getShopNPC(npcCode).pipe(
          switchMap((shop) => [
            ShopActions.openShopSuccess({ shop }),
            HudDisplayActions.hideHud({ name: 'dialog' }),
            HudDisplayActions.showHud({ name: 'shop' }),
          ]),
          catchError(() => of(ShopActions.openShopFailed())),
        ),
      ),
    ),
  );

  private _openShopFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShopActions.openShopFailed),
      tap(() =>
        this._notificationService.addNotification(
          'error',
          'Impossible de récupérer les informations de la boutique',
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
  ) {}
}
