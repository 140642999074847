import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  allChatCommands,
  talkCommands,
} from '@t12/common/chat/constants/chat-commands.const';
import {
  ChatLogColor,
  ChatLogKind,
  ChatLogScope,
} from '@t12/common/chat/enums/chat-log.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { ICreateMessageDto } from '@t12/common/chat/interfaces/create-message-dto.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  getPlayerName,
  getPlayerWorldCode,
} from '@t12/characters/store/selectors/characters.selectors';
import { TabChat } from '@t12/chat/store';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { getChat } from '@t12/chat/store/selectors/chat.selectors';
import { SocketService } from '@t12/utils/services/socket/socket.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ChatManagerService {
  constructor(
    private readonly _socket: SocketService,
    private readonly _store: Store,
    private readonly _notificationService: NotificationManagerService,
    private readonly _utilsService: UtilsService,
  ) {}

  public sendChat(message: ICreateMessageDto) {
    this._socket.emit('chat', message);
  }

  public receiveChat() {
    return this._socket.fromEvent('chat');
  }

  // Argument : Onglet à afficher
  // Résultat : Change l'onglet actif du chat  par celui renseigné
  public setActiveChatTab(tab: TabChat): void {
    this._store.dispatch(ChatActions.setTabChat({ tab }));
  }

  // Argument : Onglet du chatlog, Nom du personnage, texte du message, couleur du nom
  // Résultat : Ajoute un log dans la fenêtre de chat
  public addChatLog(
    tab: 'chat' | 'fight' | 'action',
    characterName: string,
    text: string,
    kind: ChatLogKind = ChatLogKind.Talk,
    scope: ChatLogScope = ChatLogScope.Local,
  ): ChatLog {
    if (text.length < 2) {
      this._notificationService.addNotification('error', 'Message trop court.');
      return;
    }
    const date = new Date();
    const time = `${date.getHours()}:${String(date.getMinutes()).padStart(
      2,
      '0',
    )}`;
    const layout = { nameColor: undefined, textColor: undefined };
    const chatLog: ChatLog = {
      name: characterName,
      scope,
      layout,
      text,
      time,
      kind,
      worldCode: this._utilsService.getSelect(getPlayerWorldCode),
    };

    if (chatLog.text.charAt(0) === '/') {
      chatLog.kind = this._getChatLogKindCmd(chatLog);
      this._executeCommand(chatLog);
    }

    if (talkCommands.includes(chatLog.kind))
      chatLog.text = this._cleanFormatText(chatLog.text);

    this.setChatLayout(chatLog);
    this._store.dispatch(ChatActions.addChatLog({ tab, chatLog }));

    return chatLog;
  }

  private setChatLayout(chatLog: ChatLog) {
    chatLog.layout.nameColor = ChatLogColor.Action;
    chatLog.layout.textColor = ChatLogColor.Default;

    switch (chatLog.kind) {
      case ChatLogKind.Log:
        chatLog.layout.textColor = ChatLogColor.Log;
        return;
      case ChatLogKind.Roll:
        chatLog.layout.textColor = ChatLogColor.Roll;
        return;
      case ChatLogKind.Action:
        chatLog.layout.textColor = ChatLogColor.Action;
        return;
      case ChatLogKind.Bonus:
        chatLog.layout.nameColor = ChatLogColor.Bonus;
        return;
      case ChatLogKind.Malus:
        chatLog.layout.nameColor = ChatLogColor.Malus;
        return;
    }
  }

  // Argument : ------
  // Résultat : Vide l'historique des logs du chat
  public resetLogs(): void {
    this._store.dispatch(ChatActions.init());
    this._notificationService.addNotification(
      'settings',
      'Historique du chat actif effacé.',
    );
  }

  // Argument : ------
  // Résultat : Remet le dernier message envoyé par le joueur.
  public repeatLastMsg(): string {
    const playerName = this._utilsService.getSelect(getPlayerName);
    const chat = this._utilsService.getSelect(getChat).chat;

    const lastMsg: ChatLog = chat.findLast(
      (message) => message.name === playerName,
    );

    return !talkCommands.includes(lastMsg.kind)
      ? `/${lastMsg.kind} ${lastMsg.text}`
      : lastMsg.text;
  }

  // Argument : ChatLog complet
  // Résultat : Vérifie si une commande a été saisie
  private _getChatLogKindCmd(chatLog: ChatLog): ChatLogKind {
    const cmd = chatLog.text.split('/').pop().split(' ')[0].toLowerCase();
    const foundKey = Object.keys(allChatCommands).find((key) =>
      allChatCommands[key].includes(cmd),
    );

    if (foundKey) {
      return foundKey as ChatLogKind;
    } else {
      this._notificationService.addNotification(
        'error',
        "Cette commande n'existe pas.",
      );
      return chatLog.kind;
    }
  }

  // Argument : ChatLog complet
  // Résultat : Exécute la commande présente dans le chat.type
  private _executeCommand(chat: ChatLog): void {
    switch (chat.kind) {
      case ChatLogKind.Roll:
        this._rollCommand(chat);
        break;
      case ChatLogKind.Action:
        this._meCommand(chat);
        break;
      case ChatLogKind.All:
        this._allCommand(chat);
        break;
      default:
        break;
    }
  }

  // Argument : ChatLog contenant un maximum facultatif pour limiter le roll
  // Résultat : Exécute la commande de roll pour jeter un dé de 100 par défaut
  private _rollCommand(chat?: ChatLog): void {
    const lengthCmd = chat.text.split('/').pop().split(' ')[0].length + 1;
    const max = parseInt(chat.text.substring(lengthCmd), 10) || 100;
    const rand = Math.floor(Math.random() * max);
    chat.text = ` a fait un roll de ${rand} sur ${max}.`;
    chat.layout.textColor = ChatLogColor.Roll;
  }

  // Argument : ChatLog contenant le texte descriptif de l'action
  // Résultat : Exécute la commande pour décrire une action d'un personnage
  private _meCommand(chat: ChatLog): void {
    const lengthCmd = chat.text.split('/').pop().split(' ')[0].length + 1;
    chat.text = this._cleanFormatText(chat.text.substring(lengthCmd));
    chat.layout = {
      nameColor: ChatLogColor.Action,
      textColor: ChatLogColor.Action,
    };
  }

  // Argument : ChatLog global
  // Résultat : Exécute la commande pour envoyer un message global
  private _allCommand(chat: ChatLog): void {
    const lengthCmd = chat.text.split('/').pop().split(' ')[0].length + 1;
    chat.name = '[Global] ' + chat.name;
    chat.text = this._cleanFormatText(chat.text.substring(lengthCmd));
    chat.scope = ChatLogScope.All;
  }

  // Argument : Texte à formater
  // Résultat : Renvoie la phrase en ajoutant une majuscule, un point en fin de phrase et les espaces en trop automatiquement
  private _cleanFormatText(textChat: string): string {
    let formatTextChat = textChat.charAt(0).toUpperCase() + textChat.slice(1);
    const lastCharacter = formatTextChat.charAt(formatTextChat.length - 1);
    const endCharacter = ['?', '!', '.'];

    if (!endCharacter.includes(lastCharacter)) {
      formatTextChat = `${formatTextChat}.`;
    }

    return formatTextChat.split(/\s+/).join(' ');
  }
}
