import { Injectable } from '@angular/core';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { Monster } from '@t12/common/characters/interfaces/monster.interface';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log.enums';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { attackLabel } from '@t12/fight/constants/attack-label.constant';
import { hitLabel } from '@t12/fight/constants/hit-label.constant';
import { AttackKind } from '@t12/fight/types/attack-kind.type';
import { HitKind } from '@t12/fight/types/hit-kind.type';

@Injectable({
  providedIn: 'root',
})
export class FightManagerService {
  constructor(private readonly _chatService: ChatManagerService) {}

  // Argument : Attaquant, Cible à toucher, type d'attaque, type de coup
  // Résultat : Retourne les dégats totaux et ceux potentiellement réduits
  public getDmgHit(
    attacker: Character,
    target: Character,
    attack: string,
    hit: string,
  ): number[] {
    const { stats: attackerStats } = attacker;
    const { stats: targetStats } = target;
    const targetDef = targetStats.con;
    const targetDefMagic = targetStats.sag;
    const attackFor = attackerStats.for;
    const attackDex = attackerStats.dex;
    const attackInt = attackerStats.int;
    let coefDex = 0.3;

    // TODO A convertir en reduceDmg pour avoir le bonus d'attaque dans le dos dans le chat log
    if (attack === 'physic' && attacker.looking === target.looking) {
      coefDex = 0.6;
    }

    let dmg: number;
    let reduceDmg = 0;

    if (attack === 'magic') {
      dmg = Math.floor(
        (attackInt * (attackInt * 1.1)) / (attackInt + targetDefMagic),
      );
      dmg -= Math.floor(Math.random() * Math.ceil(dmg * 0.15));
    } else {
      const attackPower = attackFor + attackDex * coefDex;
      const totalDef = attackFor + attackDex * coefDex + targetDef;
      dmg = Math.floor((attackPower * attackPower) / totalDef);
      dmg -= Math.floor(Math.random() * Math.ceil(dmg * 0.25));
    }

    if (hit === 'parry' && attack === 'physic') {
      reduceDmg = -Math.ceil(dmg * 0.25);
      dmg += reduceDmg;
    } else if (hit === 'block') {
      reduceDmg = -Math.ceil(dmg * 0.5);
      dmg += reduceDmg;
    } else if (hit === 'avoid') {
      reduceDmg = dmg;
      dmg -= reduceDmg;
    } else if (hit === 'crit') {
      reduceDmg = dmg;
      dmg += dmg;
    }

    dmg = Math.max(0, dmg);
    return [dmg, reduceDmg];
  }

  public tryToHit(
    attacker: Character,
    target: Character,
    attack: AttackKind,
  ): HitKind {
    const { lvl: attackerLvl, stats: attackerStats } = attacker;
    const { lvl: targetLvl, stats: targetStats } = target;
    let targetEquipments: Item[] = [];
    if (isPlayer(target)) targetEquipments = target.equipments;

    const { dex: attackerDex, sag: attackerSag } = attackerStats;
    const { dex: targetDex, sag: targetSag } = targetStats;
    const isBehind = attacker.looking === target.looking;
    const haveWeapon = !!(
      targetEquipments?.[5]?.code ?? (target as NPC | Monster).hasWeapon
    );
    const haveShield = !!(
      targetEquipments?.[6]?.code ?? (target as NPC | Monster).hasShield
    );
    const canParry = !!(attack === 'physic' && haveWeapon && !isBehind);
    const canBlock = !!(attack === 'physic' && haveShield && !isBehind);
    let coefAvoid: number;
    let coefCrit: number;

    if (attack === 'physic') {
      coefAvoid = Math.floor(
        16 * ((targetDex * 1.2) / attackerDex) + targetLvl / attackerLvl,
      );
      coefCrit = isBehind
        ? Math.floor((attackerDex / targetDex) * 4 + attackerDex / targetDex)
        : Math.floor((attackerDex / targetDex) * 1.5);
    } else if (attack === 'magic') {
      coefAvoid = Math.floor(
        9 * (targetSag / attackerSag) + targetLvl / attackerLvl,
      );
      coefCrit = Math.floor(attackerSag / targetSag);
    }
    coefAvoid = Math.min(Math.max(coefAvoid, 1), 75);
    coefCrit = Math.min(Math.max(coefCrit, 1), 10);

    if (haveShield) {
      coefAvoid = Math.floor(coefAvoid / 2);
    }
    return this._getKindHit(coefAvoid, coefCrit, canParry, canBlock, !isBehind);
  }

  // Argument : Dégâts et réduction, attaquant, cible, type de coup, type d'attaque
  // Résultat : Retourne les dégâts totaux et ceux potentiellement réduits
  public displayChatLogHit(
    attacker: Character,
    target: Character,
    [dmg, reduce]: number[],
    attack: AttackKind,
    hit: HitKind,
  ): void {
    const { name: attackerName, looking: attackerLooking } = attacker;
    const { name: targetName, looking: targetLooking } = target;
    const targetIsPlayer = isPlayer(target);
    const typeMe = targetIsPlayer ? ChatLogKind.Malus : ChatLogKind.Bonus;
    const typeMeOpposite = targetIsPlayer
      ? ChatLogKind.Bonus
      : ChatLogKind.Malus;
    const labelAttack = attackLabel[attack];
    const labelDmg = `a infligé ${dmg} dégâts (${labelAttack}) à ${targetName}`;
    const isBehind =
      attackerLooking === targetLooking && attackerLooking !== undefined;
    const reduceLabel = reduce > 0 ? `+${reduce}` : reduce.toString();

    let message: string;

    switch (hit) {
      case 'block':
      case 'parry':
      case 'crit':
        message = `${labelDmg} (${hitLabel[hit]}: ${reduceLabel} dégâts)`;
        this._chatService.addChatLog('fight', attackerName, message, typeMe);
        break;
      case 'hit':
        if (attack === 'physic' && isBehind) {
          message = `${labelDmg} (Coup dans le dos)`;
          this._chatService.addChatLog('fight', attackerName, message, typeMe);
        } else {
          this._chatService.addChatLog('fight', attackerName, labelDmg, typeMe);
        }
        break;
      case 'avoid':
        message = `a esquivé le coup de ${attackerName}`;
        this._chatService.addChatLog(
          'fight',
          targetName,
          message,
          typeMeOpposite,
        );
        break;
      case 'miss':
        message = `a raté ${targetName}`;
        this._chatService.addChatLog(
          'fight',
          attackerName,
          message,
          typeMeOpposite,
        );
        break;
      default:
        this._chatService.addChatLog('fight', attackerName, labelDmg, typeMe);
    }
  }

  // Argument : Coefficient d'esquive, % de critique, peut parer, peut bloquer, est dans le dos
  // Résultat : Retourne le type de l'attaque selon tous les paramètres
  private _getKindHit(
    coefAvoid: number,
    coefCrit: number,
    canParry: boolean,
    canBlock: boolean,
    canAvoid: boolean,
  ): HitKind {
    const avoid: number = coefAvoid > 1 ? coefAvoid : 1;
    const crit: number = coefCrit > 1 ? coefCrit : 1;
    const hit: number = Math.floor(Math.random() * 100);
    let typeHit: HitKind = 'hit';
    if (hit > 97 - crit) {
      typeHit = 'crit';
    } else if (hit > 19 + avoid && hit < 49 + avoid && canBlock) {
      typeHit = 'block';
    } else if (hit > 9 + avoid && hit <= 19 + avoid && canParry) {
      typeHit = 'parry';
    } else if (hit > 5 && hit <= 9 + avoid && canAvoid) {
      typeHit = 'avoid';
    } else if (hit <= 5) {
      typeHit = 'miss';
    }
    return typeHit;
  }
}
