import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { IHarvestPoint } from '@t12/common/job/interfaces/harvest-point.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { Recipe } from '@t12/common/job/interfaces/recipe.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JobActions = createActionGroup({
  source: 'JOB',
  events: {
    Init: emptyProps(),
    'learn knowledge': props<{ item: Item }>(),
    'learn knowledge success': props<{ playerJob: PlayerJob }>(),
    'learn knowledge failed': props<{ message: IHarvestPoint }>(),

    harvest: props<{ harvestPoint: IHarvestPoint }>(),
    'Harvest success': props<{ harvestPoint: IHarvestPoint }>(),
    'Harvest failed': props<{ position: Position }>(),

    'Set workshop': props<{ workshop: Workshop }>(),

    'Update Job XP': props<{ jobCode: JobCode; playerJobXp: number }>(),

    'Set active job index': props<{ index: number }>(),
    'Set active job kind': props<{ kind: JobKind }>(),
    'Set active tab': props<{ tab: TabJob }>(),
    'Set recipes': props<{ recipes: Recipe[] }>(),
    'Set displayed recipes': props<{ recipes: Recipe[] }>(),
    'Set current page': props<{ page: number }>(),
    'Set max page': props<{ maxPage: number }>(),
  },
});
