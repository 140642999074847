import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getCharacterInFrontOfPlayer,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { QuestsManagerService } from '@t12/quest/services/quests-manager/quests-manager.service';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { tap, withLatestFrom, switchMap, map, take, EMPTY, filter } from 'rxjs';
import { DialogActions } from '../actions/dialog.actions';
import {
  getDialogOptions,
  getActiveTextDialog,
  getNextTextDialog,
  getDialogCharacter,
} from '../selectors/dialog.selectors';

@Injectable()
export class DialogEffects {
  private _startConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversation),
      withLatestFrom(this._store.select(getCharacterInFrontOfPlayer)),
      switchMap(([{ npc }, character]) => {
        if (
          !isNPCOrMonster(character) ||
          npc.idCharacter !== character.idCharacter
        )
          return EMPTY;

        return this._dialogDb.getDialog(character.dialogCode).pipe(
          take(1),
          filter((textsDialog) => textsDialog.length > 0),
          tap(() => {
            if (!character.static)
              this._timerService.stopTimerMoveByID(character.idCharacter);
          }),
          switchMap((textsDialog) => [
            CharactersActions.setFaceToPlayer({
              idCharacter: character.idCharacter,
            }),
            DialogActions.setDialog({ textsDialog }),
            HudDisplayActions.showHud({ name: 'dialog' }),
          ]),
        );
      }),
    ),
  );

  private _setDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DialogActions.setDialog),
        tap(() => {
          const options: OptionDialog[] =
            this._utils.getSelect(getDialogOptions);
          if (!options) return;

          options.forEach((option) => {
            if (option.validateQuest) {
              option.disable = !this._questService.isQuestFinished(
                option.validateQuest,
              );
            }
          });
          this._store.dispatch(DialogActions.setOptions({ options }));
        }),
      ),
    { dispatch: false },
  );

  private _continueConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.continueConversation),
      withLatestFrom(
        this._store.select(getActiveTextDialog),
        this._store.select(getNextTextDialog),
      ),
      map(([_, textDialog, nextDialog]) => {
        if (!textDialog) return null;
        const options = textDialog.options;

        if (options?.length === 1 && !options[0].disable) {
          return DialogActions.chooseOption({ option: options[0] });
        } else if (nextDialog) {
          return DialogActions.nextMessage();
        } else {
          return DialogActions.endConversation();
        }
      }),
      filter((action) => !!action),
    ),
  );

  private _chooseOption$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.chooseOption),
      withLatestFrom(this._store.select(getDialogCharacter)),
      map(([{ option }, npc]) => {
        if (option.label === 'Commerce')
          return ShopActions.openShop({ npcCode: npc.code });
        else if (option.label === 'Stockage') return BankActions.openBank();

        //this._dialogService.chooseOption(option);
        return null;
      }),
      filter((action) => !!action),
    ),
  );

  private _endConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.endConversation),
      withLatestFrom(this._store.select(getDialogCharacter)),
      tap(([_, npc]) => {
        if (!npc.static && !npc.aggressive) {
          this._characterService.createPatrolInterval(npc.idCharacter);
        } else if (npc.aggressive) {
          const player = this._utils.getSelect(getPlayer);
          this._characterService.createTurnFightInterval(player, npc);
        }
      }),
      switchMap(([_, npc]) => {
        const { idCharacter, originalLooking } = npc;

        return [
          HudDisplayActions.hideHud({ name: 'dialog' }),
          CharactersActions.setLooking({
            idCharacter,
            looking: originalLooking,
          }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDb: DialogDbService,
    private readonly _characterService: CharacterManagerService,
    private readonly _questService: QuestsManagerService,
    private readonly _timerService: TimersManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}
}
