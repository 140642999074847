import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerEquipmentBySlot } from '@t12/characters/store/selectors/characters.selectors';
import { EquipmentsDbService } from '@t12/equipments/services/equipments-db/equipments-db.service';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { PopOverManagerService } from '@t12/overlay/services/pop-over/pop-over-manager.service';
import { VOLUME_NOISES } from '@t12/settings/constants/volume.constant';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { filter, map, switchMap, take, tap } from 'rxjs';

@Injectable()
export class EquipmentsEffects {
  private _equipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.equipItem),
      filter(({ item }) => !!item.slot && !!item.stats),
      switchMap(({ item }) =>
        this._store.select(getPlayerEquipmentBySlot(item.slot)).pipe(
          take(1),
          map((equipment) => {
            if (item.code === equipment?.code)
              return EquipmentsActions.equipItemFailedAlreadyHave();
            else return CharactersActions.equipItem({ item });
          }),
        ),
      ),
    ),
  );

  private _equipItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.equipItem),
      tap(({ item }) => {
        this._audioService.playSound(
          'interfaces/equipment',
          item.slot,
          'mp3',
          VOLUME_NOISES,
        );
        this._notificationService.addNotification(
          'item',
          `-1 ${item.name}`,
          5000,
          item.img,
        );
        this._equipmentDbService.addEquipment(item.code);
      }),
      map(({ item }) =>
        InventoryActions.removeItemInInventory({
          itemCode: item.code,
          amount: 1,
        }),
      ),
    ),
  );

  private _equipItemFailedAlreadyHave$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EquipmentsActions.equipItemFailedAlreadyHave),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous portez déjà cet équipement.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _unequipItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EquipmentsActions.unequipItem),
      filter(({ item }) => !!item.slot && !!item.stats),
      switchMap(({ item }) =>
        this._store.select(getFreeInventorySlotAmount(item)).pipe(
          take(1),
          map((freeAmount) => {
            if (freeAmount === 0)
              return EquipmentsActions.unequipItemFailedNoPlace();
            else return CharactersActions.unequipItem({ item });
          }),
        ),
      ),
    ),
  );

  private _unequipItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.unequipItem),
      tap(({ item }) => {
        this._audioService.playSound(
          'interfaces/equipment',
          item.slot,
          'mp3',
          VOLUME_NOISES,
        );

        this._notificationService.addNotification(
          'item',
          `+1 ${item.name}`,
          5000,
          item.img,
        );
        this._popoverService.deletePopOver();

        this._equipmentDbService.removeEquipment(item.code);
      }),
      map(({ item }) =>
        InventoryActions.addItemInInventory({
          item,
          amount: 1,
        }),
      ),
    ),
  );

  private _unequipItemFailedNoPlace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EquipmentsActions.unequipItemFailedNoPlace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas de place dans votre inventaire.",
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _equipmentDbService: EquipmentsDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _popoverService: PopOverManagerService,
    private readonly _store: Store,
  ) {}
}
