import { Player } from '@t12/common/characters/interfaces/player.interface';
import { defaultSpawnPositionConstant } from '@t12/common/player/constants/default-spawn-position.constant';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';

export const defaultDeathPlayerState = (player: Player) => {
  const { positionX, positionY, worldCode } = defaultSpawnPositionConstant;

  return {
    xp: Math.floor(player.xp / 2),
    gold: Math.floor(player.gold / 2),
    looking: 'down',
    spriteX: -40,
    spriteY: -110,
    health: maxHealth(player.stats.con),
    mana: maxMana(player.stats.int),
    position: { x: positionX, y: positionY },
    worldCode,
    top: (positionY - 1) * 32,
    left: positionX * 32,
    canMove: true,
    dead: false,
  };
};
