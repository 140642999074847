import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { ShopDbService } from '../shop-db/shop-db.service';
import { getActiveItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';

@Injectable({
  providedIn: 'root',
})
export class ShopSellService {
  constructor(
    private readonly _audioService: AudioManagerService,
    private readonly _itemDepositService: ItemDepositService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _shopDbService: ShopDbService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  // Argument : ------
  // Résultat : Permet de vendre l'objet actif si un shop est ouvert
  public sellItem(index: number, amount = 1): void {
    const item = this._utils.getSelect(getActiveItemInventory);
    if (!item || item.amount < amount) {
      return;
    }

    const gold = Math.floor(item.price * 0.5) * amount;
    this._notificationService.addNotification(
      'item',
      `-1 ${item.name} -> +${gold} OR`,
    );
    this._audioService.playSound('miscs', 'gold');
    this._store.dispatch(
      InventoryActions.removeItemInInventory({ itemCode: item.code, amount }),
    );

    this._store.dispatch(CharactersActions.addGold({ gold }));
    this._shopDbService.sellItem(item.code, amount);

    if (item.amount - 1 === 0) {
      this._store.dispatch(InventoryActions.resetActiveItem());
    }
  }
}
