import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  UpperCasePipe,
} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import {
  getPlayer,
  getPlayerGold,
  getPlayerLvl,
} from '@t12/characters/store/selectors/characters.selectors';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import {
  getActiveItemInventory,
  getPlayerInventory,
} from '@t12/inventory/store/selectors/inventory.selectors';
import { getHudShop } from '@t12/overlay/store/selectors/hud-display.selectors';
import { ShopSellService } from '@t12/shop/services/shop-sell/shop-sell.service';
import { ButtonComponent } from '@t12/ui/components/button/button.component';
import { CornersAllComponent } from '@t12/ui/components/corners-all/corners-all.component';
import { SlotItemComponent } from '@t12/ui/components/slot-item/slot-item.component';
import { SlotStatComponent } from '@t12/ui/components/slot-stat/slot-stat.component';
import { SvgGeneratorService } from '@t12/ui/services/svg-generator/svg-generator.service';
import { UnSubscriberComponent } from '@t12/utils/component/unsubscriber.component';
import { Observable } from 'rxjs';
import { ActiveItem } from '../../interfaces/active-item.interface';
import { InventoryFavoriteService } from '../../services/inventory-favorite/inventory-favorite.service';

@Component({
  selector: 'app-infos-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.less'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CornersAllComponent,
    NgFor,
    SlotStatComponent,
    ButtonComponent,
    SlotItemComponent,
    AsyncPipe,
    UpperCasePipe,
    JsonPipe,
  ],
})

// TODO: Retirer la notion d'activeItem et se baser uniquement sur l'index activeItemSlot
export class InventoryComponent
  extends UnSubscriberComponent
  implements OnInit
{
  @Input() displayInfos: boolean = true;
  public isShopOpen: Observable<boolean> = this._store.select(getHudShop);
  public player: Observable<Player> = this._store.select(getPlayer);
  public inventory: Observable<Item[]> = this._store.select(getPlayerInventory);
  public playerGold = this._store.select(getPlayerGold);
  public playerLvl: Observable<number> = this._store.select(getPlayerLvl);
  public svgStats: Record<string, SafeHtml>;
  public svgInfos: Record<string, SafeHtml>;
  public activeItem: ActiveItem;

  constructor(
    public readonly inventoryFavoriteService: InventoryFavoriteService,
    public readonly inventorySellService: ShopSellService,
    private readonly _store: Store,
    private readonly _svgService: SvgGeneratorService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.svgStats = this._svgService.generateIconStat();
    this.svgInfos = this._svgService.generateIconInfo();

    this._store
      .select(getActiveItemInventory)
      .pipe(this.takeUntilDestroyed())
      .subscribe((activeItem) => {
        this.activeItem = activeItem;
      });
  }

  trackItemByCode(item: any): string {
    return item.code;
  }

  // Arguments : Index du slot inventaire
  // Résultat : Ajoute l'objet correspondant en tant qu'actif
  public setActiveItem(index: number): void {
    this._store.dispatch(InventoryActions.setActiveItem({ index }));
  }

  dropItem() {
    this._store.dispatch(
      InventoryActions.dropItem({ itemCode: this.activeItem.code, amount: 1 }),
    );
  }

  useItem(itemCode: string) {
    this._store.dispatch(InventoryActions.useItem({ itemCode }));
  }
}
