import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JobState } from '@t12/jobs/store';

const selectRoot = createFeatureSelector<JobState>('job');

export const getJobState = createSelector(selectRoot, (job: JobState) => job);

export const getActiveJobIndex = createSelector(
  selectRoot,
  ({ activeJobIndex }) => activeJobIndex,
);

export const getActiveJobKind = createSelector(
  selectRoot,
  ({ activeJobKind }) => activeJobKind,
);

export const getActiveTabJob = createSelector(
  selectRoot,
  ({ activeTab }) => activeTab,
);

export const getRecipes = createSelector(selectRoot, ({ recipes }) => recipes);

export const getDisplayedRecipes = createSelector(
  selectRoot,
  ({ displayedRecipes }) => displayedRecipes,
);

export const getCurrentPageRecipe = createSelector(
  selectRoot,
  ({ currentPage }) => currentPage,
);

export const getMaxPageRecipes = createSelector(
  selectRoot,
  ({ maxPage }) => maxPage,
);

export const getWorkshop = createSelector(
  selectRoot,
  ({ workshop }) => workshop,
);
