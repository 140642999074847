import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DialogState } from '../index';

const selectRoot = createFeatureSelector<DialogState>('dialog');

export const getDialog = createSelector(
  selectRoot,
  (dialog: DialogState) => dialog,
);

export const getDialogCharacter = createSelector(
  selectRoot,
  ({ character }) => character,
);

export const getActiveTextDialog = createSelector(
  selectRoot,
  ({ texts, index }) => texts[index],
);

export const getNextTextDialog = createSelector(
  selectRoot,
  ({ texts, index }) => !!texts[index + 1],
);

export const getDialogOptions = createSelector(
  selectRoot,
  ({ texts, index }) => texts[index]?.options,
);
