import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { NPC } from '@t12/common/characters/interfaces/npc.interface';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { QuestInfos } from '@t12/common/quest/interfaces/infos-quest.interface';
import { ItemDepositService } from '@t12/container/services/items-deposit/item-deposit.service';
import { InventoryUtilsService } from '@t12/inventory/services/inventory-utils/inventory-utils.service';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { PlayerManagerService } from '@t12/player/services/player-manager/player-manager.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { getQuestsInfos } from '@t12/quest/store/selectors/quest.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { take } from 'rxjs';
import { QuestDbService } from '../quest-db/quest-db.service';
import { QuestGoalsService } from '../quest-goals/quest-goals.service';

@Injectable({
  providedIn: 'root',
})
export class QuestsManagerService {
  constructor(
    private readonly _inventoryUtilsService: InventoryUtilsService,
    private readonly _itemDepositService: ItemDepositService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerService: PlayerManagerService,
    private readonly _questDbService: QuestDbService,
    private readonly _questGoalsService: QuestGoalsService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  public canGetRewardQuest(items: Item[]): boolean {
    if (!items) return true;

    return items.every(
      (item) =>
        item.amount <=
        this._inventoryUtilsService.getFreeInventorySlotAmount(item),
    );
  }

  // Argument : ------
  // Résultat : Distribue les récompenses de la quête active
  public rewardQuest(quest: QuestInfos, npcIdCharacter: number): void {
    const { gold, xp, rewardItems = [] as Item[] } = quest;
    this._playerService.updateInfosPlayerCharacter('gold', gold);
    this._playerService.updateInfosPlayerCharacter('xp', xp);

    if (rewardItems && rewardItems.length > 0) {
      const [item] = rewardItems as Item[];
      this._itemDepositService.addItemInto(ContainerKind.INVENTORY, item);
      this._notificationService.addNotification(
        'validation',
        `+${item.amount} ${item.name}`,
        5000,
        item.img,
      );
    }
    this._store.dispatch(
      CharactersActions.setIsDoneQuest({
        idCharacter: npcIdCharacter,
        isDone: false,
      }),
    );
    this._questDbService.validateQuest(quest.code).pipe(take(1)).subscribe();
  }

  public addQuest(questCode: string, npc: NPC): void {
    const { code: npcCode, idCharacter } = npc;
    this._questDbService
      .initQuestPlayer(questCode, npcCode)
      .pipe(take(1))
      .subscribe((questInfos) => {
        this._notificationService.addNotification(
          'quest',
          `Nouvelle quête ajoutée.`,
        );
        this._store.dispatch(QuestActions.addQuest({ questInfos }));
        this._store.dispatch(
          CharactersActions.setIsDoneQuest({ idCharacter, isDone: false }),
        );
        this._questGoalsService.updateCollectGoals();
        this._store.dispatch(
          CharactersActions.setIsDoneQuest({
            idCharacter,
            isDone: this.isQuestFinished(questInfos.code),
          }),
        );
      });
    this._store.dispatch(
      CharactersActions.setHaveQuest({ idCharacter, hasQuest: false }),
    );
  }

  public removeQuest(questCode: string): void {
    this._store.dispatch(QuestActions.removeQuest({ questCode }));
  }

  public getQuestPlayer(questCode: string): QuestInfos {
    const quests = this._utils.getSelect(getQuestsInfos);

    return quests.find((quest) => quest.code === questCode);
  }

  public isQuestFinished(questCode: string): boolean {
    const quest = this.getQuestPlayer(questCode);
    if (!quest) return false;

    return quest.goals.every((goal) => goal.amount === goal.amountTotal);
  }

  public removeItemsQuest(activeQuest: QuestInfos): void {
    activeQuest.goals.forEach((goal) => {
      if (goal.kind === 'collect') {
        this._itemDepositService.removeItemFrom(
          ContainerKind.INVENTORY,
          goal.entityCode,
          goal.amountTotal,
        );
      }
    });
  }
}
