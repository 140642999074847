import { Injectable, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { getPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { Player } from '@t12/common/characters/interfaces/player.interface';
import { itemTypesItemConsumable } from '@t12/common/item/constants/item-types-consumable.constant';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { LocalService } from '@t12/utils/services/local/local.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { HotkeyState } from '@t12/utils/store/hotkey';
import { HotkeyActions } from '@t12/utils/store/hotkey/actions/hotkey.actions';
import { getHotkeys } from '@t12/utils/store/hotkey/selectors/hotkey.selectors';

@Injectable({
  providedIn: 'root',
})
export class InventoryFavoriteService {
  private screenWidth: number;

  constructor(
    private readonly _localService: LocalService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {}

  @HostListener('window:resize', ['$event'])
  private _getScreenSize(): void {
    this.screenWidth = window.innerWidth;
  }

  // Argument : ------
  // Résultat : Permet d'assigner l'objet actif en favoris
  public setFavorite(code: string, type: ItemType): void {
    let hotkeys: HotkeyState[] = this._utils.getSelect(getHotkeys);
    const hotkeyIndex = hotkeys.findIndex(
      (hotkeyItem) => hotkeyItem.entity.code === code,
    );
    const player: Player = this._utils.getSelect(getPlayer);
    const { id: playerId, inventory } = player;

    if (hotkeyIndex !== -1) {
      this._store.dispatch(HotkeyActions.removeHotkey({ code }));
    } else if (hotkeys.length > 4 && hotkeyIndex === -1) {
      this._notificationService.addNotification(
        'error',
        "Vous n'avez plus de raccourcis libre!",
      );
      return;
    } else {
      const itemIndex = inventory.findIndex(
        (itemInventory) =>
          itemInventory.code === code && itemInventory.type === type,
      );
      const item = inventory[itemIndex];

      if (!item || !itemTypesItemConsumable.includes(item.type)) {
        this._notificationService.addNotification(
          'error',
          'Vous ne pouvez pas mettre cet objet en raccourcis!',
        );
        return;
      }
      const hotkey: HotkeyState = {
        entity: {
          type: item.type,
          code: item.code,
          quality: item.quality,
          img: item.img,
        },
      };
      this._getScreenSize();

      if (this.screenWidth > 1224) {
        this._store.dispatch(HotkeyActions.addHotkey({ hotkey }));
      } else {
        this._store.dispatch(HotkeyActions.setHotkeyAt({ hotkey, index: 0 }));
      }
    }
    hotkeys = this._utils.getSelect(getHotkeys);
    this._localService.setJsonValue(
      `hotkeys${playerId}`,
      JSON.stringify(hotkeys),
    );
  }
}
